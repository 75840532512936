import { RouteRecordRaw } from "vue-router";
import routesName from "@/utils/constants/routesName";

const loadView = (path: string) => {
  return () => import(`@/views/${path}.vue`);
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/sign-in",
    name: routesName.signIn,
    component: loadView("SignIn"),
    meta: { notAuthorized: true },
  },
  {
    path: "/",
    name: routesName.users,
    component: loadView("UsersList"),
    meta: { protected: true },
  },
  {
    path: "/admins",
    name: routesName.admins,
    component: loadView("AdminsList"),
    meta: { protected: true, onlySuperAdmin: true },
  },
  {
    path: "/admins/create",
    name: routesName.adminCreate,
    component: loadView("CreateAdmin"),
    meta: { protected: true, onlySuperAdmin: true },
  },
  {
    path: "/profile",
    name: routesName.profile,
    component: loadView("AdminProfile"),
    meta: { protected: true },
  },
  {
    path: "/shops",
    name: routesName.shopsList,
    component: loadView("ShopsList"),
    meta: { protected: true },
  },
];

export default routes;
