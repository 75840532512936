import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
} from "vue-router";
import routes from "@/router/routes";
import { useUserStore } from "@/store/user";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();
  if (isProtected(to)) {
    if (isSuperAdminOnly(to)) {
      if (userStore.accessToken && userStore.isRoleSuperAdmin) {
        next();
        return;
      }
    } else if (userStore.accessToken) {
      next();
      return;
    }
    next({
      path: "/sign-in",
      query: { redirect: to.path },
    });
    return;
  }
  if (to.matched.some((record) => record.meta.notAuthorized)) {
    if (!userStore.accessToken) {
      next();
      return;
    } else {
      next({
        path: "/",
      });
      return;
    }
  }
  next();
});

const isProtected = (to: RouteLocationNormalized): boolean => {
  return to.matched.some((record) => record.meta.protected);
};
const isSuperAdminOnly = (to: RouteLocationNormalized): boolean => {
  return to.matched.some((record) => record.meta.onlySuperAdmin);
};

export default router;
