const routesName = {
  signIn: "signIn",
  admins: "admins",
  users: "users",
  profile: "profile",
  adminCreate: "adminCreate",
  shopsList: "shopsList",
};

export default routesName;
