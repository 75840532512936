import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75b59190"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "empty-layout"
}
const _hoisted_2 = {
  key: 1,
  class: "main-layout"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return ($setup.isFullPageLayout)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_a_layout, {
          class: "layout-default",
          id: "layout-default"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_layout_content, null, {
              default: _withCtx(() => [
                _createVNode(_component_router_view, null, {
                  default: _withCtx(({ Component }) => [
                    _createVNode(_Transition, {
                      name: "fade",
                      mode: "out-in"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_a_layout, {
          class: "layout-dashboard",
          id: "layout-dashboard"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["AppSidebar"], {
              sidebarcolor: $setup.sidebarColor,
              sidebartheme: $setup.sidebarTheme
            }, null, 8, ["sidebarcolor", "sidebartheme"]),
            _createVNode(_component_a_layout, null, {
              default: _withCtx(() => [
                _createVNode($setup["AppHeader"]),
                _createVNode(_component_a_layout_content, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_view, null, {
                      default: _withCtx(({ Component }) => [
                        _createVNode(_Transition, {
                          name: "fade",
                          mode: "out-in"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
}