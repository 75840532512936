import { defineStore } from "pinia";
import { computed, Ref, ref } from "vue";
import router from "@/router";
import routesName from "@/utils/constants/routesName";
import { UserRoleEnum } from "@/utils/enum/UserRoleEnum";
import { IProfile } from "@/types/IProfile";

export const useUserStore = defineStore(
  "user",
  () => {
    const userInfo = ref<IProfile | null>(null);
    const userRoles = ref<UserRoleEnum[] | null>(null);
    const accessToken: Ref<string> = ref("");
    const refreshToken: Ref<string> = ref("");

    const setUserInfo = (data: IProfile): void => {
      userInfo.value = data;
    };

    const setTokens = (payload: {
      accessToken: string;
      refreshToken: string;
    }): void => {
      refreshToken.value = payload.refreshToken;
      accessToken.value = payload.accessToken;
    };

    const setUserRole = (roles: UserRoleEnum[]): void => {
      userRoles.value = roles;
    };

    const clear = (): void => {
      userInfo.value = null;
      accessToken.value = "";
      refreshToken.value = "";
      router.push({ name: routesName.signIn });
    };

    const isRoleSuperAdmin = computed<boolean>((): boolean => {
      return !!userRoles?.value?.includes(UserRoleEnum.superAdmin);
    });

    return {
      userInfo,
      userRoles,
      accessToken,
      refreshToken,
      setTokens,
      clear,
      isRoleSuperAdmin,
      setUserInfo,
      setUserRole,
    };
  },
  {
    persist: {
      paths: ["accessToken", "refreshToken", "userInfo", "userRoles"],
    },
  }
);
